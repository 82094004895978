import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <h1 className="text-3xl font-bold underline">
     Coming soon!
    </h1>

  );
}

export default App;
